<template>
  <div class="ca-page">
    <v-row class="ca-page-title">
      <v-col>
        <h1 class="ca-title">Design Style</h1>
        <h2 class="ca-subtitle">
          Which of these examples best represents your style?
        </h2>
      </v-col>
    </v-row>
    <v-row class="ca-content">
      <v-col cols="12" class="ca-banners ca-options">
        <v-row v-for="(chunk, i) in chunkBanners" :key="i">
          <v-col v-for="(col, i) in chunk" md="6" sm="12" :key="i">
            <banner :option="col.option" :pictures="col.pictures" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from "lodash";
import Banner from "../components/Banner.vue";

export default {
  data() {
    return {
      banners: [
        {
          option: "established",
          pictures: [
            {
              brand: "Acura",
              src: require("../assets/images/banners/Acura/CM-EC-24-Acura-Established.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/087/original/CM-EC-24-Acura-Established.jpg?1714510140"
            },
            {
              brand: "BMW",
              src: require("../assets/images/banners/BMW/CM-BS-24-BMW-Established.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/090/original/CM-BS-24-BMW-Established.jpg?1714510150"
            },
            {
              brand: "Buick",
              src: require("../assets/images/banners/Buick/CM-MS-24-Buick-Established.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/095/original/CM-MS-24-Buick-Established.jpg?1714510162"
            },
            {
              brand: "Cadillac",
              src: require("../assets/images/banners/Cadillac/CM-NL-24-Cadillac-Established.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/099/original/CM-NL-24-Cadillac-Established.jpg?1714510173"
            },
            {
              brand: "Chevrolet",
              src: require("../assets/images/banners/Chevrolet/CM-JN-24-Chevy-Established.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/103/original/CM-JN-24-Chevy-Established.jpg?1714510182"
            },
            {
              brand: "CJDR",
              src: require("../assets/images/banners/CJDR/CM-BS-24-CJDR-Established.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/107/original/CM-BS-24-CJDR-Established.jpg?1714510193"
            },
            {
              brand: "Ford",
              src: require("../assets/images/banners/Ford/CM-DJ-24-Ford-Established.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/110/original/CM-DJ-24-Ford-Established.jpg?1714510202"
            },
            {
              brand: "GMC",
              src: require("../assets/images/banners/GMC/CM-BS-24-GMC-Established.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/114/original/CM-BS-24-GMC-Established.jpg?1714510211"
            },
            {
              brand: "Honda",
              src: require("../assets/images/banners/Honda/CM-SL-24-Honda-Established.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/119/original/CM-SL-24-Honda-Established.jpg?1714510220"
            },
            {
              brand: "Hyundai",
              src: require("../assets/images/banners/Hyundai/CM-JN-24-Hyundai-Established.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/122/original/CM-JN-24-Hyundai-Established.jpg?1714510232"
            },
            {
              brand: "INFINITI",
              src: require("../assets/images/banners/Infiniti/CM-AB-24-INFINITI-Established.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/126/original/CM-AB-24-INFINITI-Established.jpg?1714510242"
            },
            {
              brand: "Kia",
              src: require("../assets/images/banners/Kia/CM-PW-24-Kia-Established.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/131/original/CM-PW-24-Kia-Established.jpg?1714510257"
            },
            {
              brand: "Lincoln",
              src: require("../assets/images/banners/Lincoln/CM-SL-CA-Lincoln-Established.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/134/original/CM-SL-CA-Lincoln-Established.jpg?1714510265"
            },
            {
              brand: "Mazda",
              src: require("../assets/images/banners/Mazda/CM-KT-24-Mazda-Established.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/139/original/CM-KT-24-Mazda-Established.jpg?1714510274"
            },
            {
              brand: "Mitsubishi",
              src: require("../assets/images/banners/Mitsubishi/CM-MS-24-Mitsubishi-Established.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/142/original/CM-MS-24-Mitsubishi-Established.jpg?1714510284"
            },
            {
              brand: "Nissan",
              src: require("../assets/images/banners/Nissan/CM-SL-24-Nissan-Established.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/146/original/CM-SL-24-Nissan-Established.jpg?1714510292"
            },
            {
              brand: "Subaru",
              src: require("../assets/images/banners/Subaru/CM-SS-24-Subaru-Established.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/151/original/CM-SS-24-Subaru-Established.jpg?1714510300"
            },
            {
              brand: "Toyota",
              src: require("../assets/images/banners/Toyota/CM-MS-24-Toyota-Established.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/155/original/CM-MS-24-Toyota-Established.jpg?1714510309"
            },
            {
              brand: "Volkswagen",
              src: require("../assets/images/banners/Volkswagen/CM-NL-24-Volkswagen-Established.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/159/original/CM-NL-24-Volkswagen-Established.jpg?1714510318"
            },
            {
              brand: "Volvo",
              src: require("../assets/images/banners/Volvo/CM-BS-24-Volvo-Established.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/162/original/CM-BS-24-Volvo-Established.jpg?1714510325"
            },
          ],
        },
        {
          option: "lifestyle",
          pictures: [
            {
              brand: "Acura",
              src: require("../assets/images/banners/Acura/CM-EC-24-Acura-Lifestyle.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/086/original/CM-EC-24-Acura-Lifestyle.jpg?1714510140"
            },
            {
              brand: "BMW",
              src: require("../assets/images/banners/BMW/CM-BS-24-BMW-Lifestyle.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/091/original/CM-BS-24-BMW-Lifestyle.jpg?1714510150"
            },
            {
              brand: "Buick",
              src: require("../assets/images/banners/Buick/CM-MS-24-Buick-Lifestyle.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/094/original/CM-MS-24-Buick-Lifestyle.jpg?1714510162"
            },
            {
              brand: "Cadillac",
              src: require("../assets/images/banners/Cadillac/CM-NL-24-Cadillac-Lifestyle.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/098/original/CM-NL-24-Cadillac-Lifestyle.jpg?1714510172"
            },
            {
              brand: "Chevrolet",
              src: require("../assets/images/banners/Chevrolet/CM-JN-24-Chevy-Lifestyle.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/102/original/CM-JN-24-Chevy-Lifestyle.jpg?1714510182"
            },
            {
              brand: "CJDR",
              src: require("../assets/images/banners/CJDR/CM-BS-24-CJDR-lifestyle.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/106/original/CM-BS-24-CJDR-lifestyle.jpg?1714510193"
            },
            {
              brand: "Ford",
              src: require("../assets/images/banners/Ford/CM-DJ-24-Ford-Lifestyle.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/111/original/CM-DJ-24-Ford-Lifestyle.jpg?1714510202"
            },
            {
              brand: "GMC",
              src: require("../assets/images/banners/GMC/CM-BS-24-GMC-Lifestyle.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/115/original/CM-BS-24-GMC-Lifestyle.jpg?1714510211"
            },
            {
              brand: "Honda",
              src: require("../assets/images/banners/Honda/CM-SL-24-Honda-Lifestyle.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/118/original/CM-SL-24-Honda-Lifestyle.jpg?1714510220"
            },
            {
              brand: "Hyundai",
              src: require("../assets/images/banners/Hyundai/CM-JN-24-Hyundai-Lifestyle.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/123/original/CM-JN-24-Hyundai-Lifestyle.jpg?1714510232"
            },
            {
              brand: "INFINITI",
              src: require("../assets/images/banners/Infiniti/CM-AB-24-INFINITI-Lifestyle-v1.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/127/original/CM-AB-24-INFINITI-Lifestyle-v1.jpg?1714510242"
            },
            {
              brand: "Kia",
              src: require("../assets/images/banners/Kia/CM-PW-24-Kia-Lifestyle.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/130/original/CM-PW-24-Kia-Lifestyle.jpg?1714510256"
            },
            {
              brand: "Lincoln",
              src: require("../assets/images/banners/Lincoln/CM-SL-CA-Lincoln-Lifestyle.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/135/original/CM-SL-CA-Lincoln-Lifestyle.jpg?1714510265"
            },
            {
              brand: "Mazda",
              src: require("../assets/images/banners/Mazda/CM-KT-24-Mazda-Lifestyle.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/138/original/CM-KT-24-Mazda-Lifestyle.jpg?1714510274"
            },
            {
              brand: "Mitsubishi",
              src: require("../assets/images/banners/Mitsubishi/CM-MS-24-Mitsubishi-Lifestyle.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/143/original/CM-MS-24-Mitsubishi-Lifestyle.jpg?1714510284"
            },
            {
              brand: "Nissan",
              src: require("../assets/images/banners/Nissan/CM-SL-24-Nissan-Lifestyle.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/147/original/CM-SL-24-Nissan-Lifestyle.jpg?1714510292"
            },
            {
              brand: "Subaru",
              src: require("../assets/images/banners/Subaru/CM-SS-24-Subaru-Lifestyle.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/150/original/CM-SS-24-Subaru-Lifestyle.jpg?1714510300"
            },
            {
              brand: "Toyota",
              src: require("../assets/images/banners/Toyota/CM-MS-24-Toyota-Lifestyle.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/154/original/CM-MS-24-Toyota-Lifestyle.jpg?1714510309"
            },
            {
              brand: "Volkswagen",
              src: require("../assets/images/banners/Volkswagen/CM-NL-24-Volkswagen-Lifestyle.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/158/original/CM-NL-24-Volkswagen-Lifestyle.jpg?1714510317"
            },
            {
              brand: "Volvo",
              src: require("../assets/images/banners/Volvo/CM-BS-24-Volvo-Lifestyle-Enviro.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/163/original/CM-BS-24-Volvo-Lifestyle-Enviro.jpg?1714510325"
            },
          ],
        },
        {
          option: "modern",
          pictures: [
            {
              brand: "Acura",
              src: require("../assets/images/banners/Acura/CM-EC-24-Acura-Modern.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/088/original/CM-EC-24-Acura-Modern.jpg?1714510141"
            },
            {
              brand: "BMW",
              src: require("../assets/images/banners/BMW/CM-BS-24-BMW-Modern.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/092/original/CM-BS-24-BMW-Modern.jpg?1714510151"
            },
            {
              brand: "Buick",
              src: require("../assets/images/banners/Buick/CM-MS-24-Buick-Modern.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/096/original/CM-MS-24-Buick-Modern.jpg?1714510163"
            },
            {
              brand: "Cadillac",
              src: require("../assets/images/banners/Cadillac/CM-NL-24-Cadillac-Modern.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/100/original/CM-NL-24-Cadillac-Modern.jpg?1714510173"
            },
            {
              brand: "Chevrolet",
              src: require("../assets/images/banners/Chevrolet/CM-JN-24-Chevy-Modern.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/105/original/CM-JN-24-Chevy-Modern.jpg?1714510183"
            },
            {
              brand: "CJDR",
              src: require("../assets/images/banners/CJDR/CM-BS-24-CJDR-Modern.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/108/original/CM-BS-24-CJDR-Modern.jpg?1714510194"
            },
            {
              brand: "Ford",
              src: require("../assets/images/banners/Ford/CM-DJ-24-Ford-Modern.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/112/original/CM-DJ-24-Ford-Modern.jpg?1714510203"
            },
            {
              brand: "GMC",
              src: require("../assets/images/banners/GMC/CM-BS-24-GMC-Modern.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/117/original/CM-BS-24-GMC-Modern.jpg?1714510212"
            },
            {
              brand: "Honda",
              src: require("../assets/images/banners/Honda/CM-SL-24-Honda-Modern.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/120/original/CM-SL-24-Honda-Modern.jpg?1714510220"
            },
            {
              brand: "Hyundai",
              src: require("../assets/images/banners/Hyundai/CM-JN-24-Hyundai-Modern.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/124/original/CM-JN-24-Hyundai-Modern.jpg?1714510233"
            },
            {
              brand: "INFINITI",
              src: require("../assets/images/banners/Infiniti/CM-AB-24-INFINITI-Modern.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/129/original/CM-AB-24-INFINITI-Modern.jpg?1714510247"
            },
            {
              brand: "Kia",
              src: require("../assets/images/banners/Kia/CM-PW-24-Kia-Modern.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/132/original/CM-PW-24-Kia-Modern.jpg?1714510257"
            },
            {
              brand: "Lincoln",
              src: require("../assets/images/banners/Lincoln/CM-SL-CA-Lincoln-Modern.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/136/original/CM-SL-CA-Lincoln-Modern.jpg?1714510265"
            },
            {
              brand: "Mazda",
              src: require("../assets/images/banners/Mazda/CM-KT-24-Mazda-Modern.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/140/original/CM-KT-24-Mazda-Modern.jpg?1714510275"
            },
            {
              brand: "Mitsubishi",
              src: require("../assets/images/banners/Mitsubishi/CM-MS-24-Mitsubishi-Modern.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/144/original/CM-MS-24-Mitsubishi-Modern.jpg?1714510284"
            },
            {
              brand: "Nissan",
              src: require("../assets/images/banners/Nissan/CM-SL-24-Nissan-Modern.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/148/original/CM-SL-24-Nissan-Modern.jpg?1714510292"
            },
            {
              brand: "Subaru",
              src: require("../assets/images/banners/Subaru/CM-SS-24-Subaru-Modern.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/153/original/CM-SS-24-Subaru-Modern.jpg?1714510301"
            },
            {
              brand: "Toyota",
              src: require("../assets/images/banners/Toyota/CM-MS-24-Toyota-Modern.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/156/original/CM-MS-24-Toyota-Modern.jpg?1714510309"
            },
            {
              brand: "Volkswagen",
              src: require("../assets/images/banners/Volkswagen/CM-NL-24-Volkswagen-Modern.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/160/original/CM-NL-24-Volkswagen-Modern.jpg?1714510318"
            },
            {
              brand: "Volvo",
              src: require("../assets/images/banners/Volvo/CM-BS-24-Volvo-Modern.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/164/original/CM-BS-24-Volvo-Modern.jpg?1714510325"
            },
          ],
        },
        {
          option: "playful",
          pictures: [
            {
              brand: "Acura",
              src: require("../assets/images/banners/Acura/CM-EC-24-Acura-Playful.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/089/original/CM-EC-24-Acura-Playful.jpg?1714510141"
            },
            {
              brand: "BMW",
              src: require("../assets/images/banners/BMW/CM-BS-24-BMW-Playful.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/093/original/CM-BS-24-BMW-Playful.jpg?1714510151"
            },
            {
              brand: "Buick",
              src: require("../assets/images/banners/Buick/CM-MS-24-Buick-Playful.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/097/original/CM-MS-24-Buick-Playful.jpg?1714510163"
            },
            {
              brand: "Cadillac",
              src: require("../assets/images/banners/Cadillac/CM-NL-24-Cadillac-Playful.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/101/original/CM-NL-24-Cadillac-Playful.jpg?1714510173"
            },
            {
              brand: "Chevrolet",
              src: require("../assets/images/banners/Chevrolet/CM-JN-24-Chevy-Playful.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/104/original/CM-JN-24-Chevy-Playful.jpg?1714510183"
            },
            {
              brand: "CJDR",
              src: require("../assets/images/banners/CJDR/CM-BS-24-CJDR-Playful.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/109/original/CM-BS-24-CJDR-Playful.jpg?1714510194"
            },
            {
              brand: "Ford",
              src: require("../assets/images/banners/Ford/CM-DJ-24-Ford-Playful.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/113/original/CM-DJ-24-Ford-Playful.jpg?1714510203"
            },
            {
              brand: "GMC",
              src: require("../assets/images/banners/GMC/CM-BS-24-GMC-Playful.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/116/original/CM-BS-24-GMC-Playful.jpg?1714510211"
            },
            {
              brand: "Honda",
              src: require("../assets/images/banners/Honda/CM-SL-24-Honda-Playful.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/121/original/CM-SL-24-Honda-Playful.jpg?1714510225"
            },
            {
              brand: "Hyundai",
              src: require("../assets/images/banners/Hyundai/CM-JN-24-Hyundai-Playful.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/125/original/CM-JN-24-Hyundai-Playful.jpg?1714510233"
            },
            {
              brand: "INFINITI",
              src: require("../assets/images/banners/Infiniti/CM-AB-24-INFINITI-Playful.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/128/original/CM-AB-24-INFINITI-Playful.jpg?1714510247"
            },
            {
              brand: "Kia",
              src: require("../assets/images/banners/Kia/CM-PW-24-Kia-Playful.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/133/original/CM-PW-24-Kia-Playful.jpg?1714510257"
            },
            {
              brand: "Lincoln",
              src: require("../assets/images/banners/Lincoln/CM-SL-CA-Lincoln-Playful.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/137/original/CM-SL-CA-Lincoln-Playful.jpg?1714510266"
            },
            {
              brand: "Mazda",
              src: require("../assets/images/banners/Mazda/CM-KT-24-Mazda-Playful.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/141/original/CM-KT-24-Mazda-Playful.jpg?1714510275"
            },
            {
              brand: "Mitsubishi",
              src: require("../assets/images/banners/Mitsubishi/CM-MS-24-Mitsubishi-Playful.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/145/original/CM-MS-24-Mitsubishi-Playful.jpg?1714510284"
            },
            {
              brand: "Nissan",
              src: require("../assets/images/banners/Nissan/CM-SL-24-Nissan-Playful.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/149/original/CM-SL-24-Nissan-Playful.jpg?1714510292"
            },
            {
              brand: "Subaru",
              src: require("../assets/images/banners/Subaru/CM-SS-24-Subaru-Playful.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/152/original/CM-SS-24-Subaru-Playful.jpg?1714510301"
            },
            {
              brand: "Toyota",
              src: require("../assets/images/banners/Toyota/CM-MS-24-Toyota-Playful.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/157/original/CM-MS-24-Toyota-Playful.jpg?1714510309"
            },
            {
              brand: "Volkswagen",
              src: require("../assets/images/banners/Volkswagen/CM-NL-24-Volkswagen-Playful.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/161/original/CM-NL-24-Volkswagen-Playful.jpg?1714510318"
            },
            {
              brand: "Volvo",
              src: require("../assets/images/banners/Volvo/CM-BS-24-Volvo-Playful.jpg"),
              websrc: "https://d2v1gjawtegg5z.cloudfront.net/uploads/files/000/874/165/original/CM-BS-24-Volvo-Playful.jpg?1714510326"
            },
          ],
        },
      ],
    };
  },
  mounted() {
    this.sortPictures();
    window.scrollTo(0, 0);
  },
  methods: {
    sortPictures() {
      const brand = this.$store.state.brand;
      const brandExists = this.banners[0].pictures.findIndex(
        (el) => el.brand === brand
      );
      if (brandExists === -1) return;
      this.banners.forEach((category, i) => {
        const brandPicture = category.pictures.find(
          (picture) => picture.brand === brand
        );
        let filtered = category.pictures.filter((picture) => {
          return picture.brand !== brand;
        });
        filtered.unshift(brandPicture);
        this.banners[i].pictures = filtered;
      });
    },
  },
  computed: {
    chunkBanners() {
      return _.chunk(this.banners, 2);
    },
  },
  components: {
    Banner,
  },
};
</script>
