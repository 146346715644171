<template>
  <div
    class="ca-banner ca-option"
    :class="{ chosen: chosen }"
    @click="updateChoice(option, 'banner'); updateURLChoice(url, 'bannerImg')"
  >
    <v-carousel
      @click.stop
      height="auto"
      hide-delimiters
      :cycle="chosen"
      interval="3500"
    >
      <v-carousel-item eager v-for="(picture, i) in pictures" :key="i">
        <v-img
          class="banner-img"
          eager
          :src="picture.src"
          style="height: auto"
        />
      </v-carousel-item>
    </v-carousel>

    <h2 class="ca-card-title mb-0 text-center text-capitalize">
      {{ option }}
    </h2>
  </div>
</template>

<script>
import updateChoice from "../mixins/mixin";
import updateURLChoice from "../mixins/mixin";
import { mapGetters } from "vuex";

export default {
  props: {
    option: String,
    pictures: Array,
  },
  mixins: [updateChoice, updateURLChoice],
  computed: {
    ...mapGetters(["getChoice"]),
    chosen() {
      return this.getChoice("banner") === this.option;
    },
    url() {
      const brand = this.$store.state.brand;
      const brandExists = this.pictures.findIndex(
        (el) => el.brand === brand
      );
      
      if (brandExists !== -1) {
        let rightPicture = this.pictures.find(p =>
          p.brand === brand
        )
        return rightPicture.websrc
      } else {
        return this.pictures[0].websrc
      }
    }
  },
};
</script>

<style scoped>
</style>
